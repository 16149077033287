<template>
  <div>
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-header">
        <div class="page-wrapper-table-header-left">
          <base-input
            v-model="query"
            type="search"
            class="search"
            prepend-icon="far fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
          />
          <el-select
            v-if="!filterStatus"
            :placeholder="$t('COMMON.STATUS')"
            v-model="selectedStatus"
          >
            <el-option :value="0" :label="$t('COMMON.ALL_STATUSES')">
            </el-option>
            <el-option
              v-for="(value, key) in statusesOptions"
              :key="key"
              :value="key"
              :label="value"
            >
            </el-option>
          </el-select>
          <reseller-selector
            v-if="
              !filterReseller &&
              !filterRecipient &&
              $currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)
            "
            @resellerChanged="(resellerId) => (selectedReseller = resellerId)"
          />
          <base-input class="dates" placeholder="Dates">
            <flat-picker
              :config="{
                allowInput: true,
                mode: 'range',
                locale: flatPickrLocale,
              }"
              class="form-control datepicker"
              v-model="dateRange"
              placeholder="Dates"
            >
            </flat-picker>
          </base-input>
        </div>
        <div class="page-wrapper-table-header-right">
          <el-select
            class="select-primary pagination-select"
            v-model="pagination.perPage"
            :placeholder="$t('COMMON.PER_PAGE')"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div v-if="resellerInvoices.length > 0" class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="resellerInvoices"
            @sort-change="sortChange"
          >
            <div class="table-loading" slot="empty" v-if="loading">
              <img src="/img/loading.gif" />
            </div>
            <el-table-column
              :label="$t('COMMON.CODE')"
              prop="code"
              sortable="custom"
              min-width="200"
            />
            <el-table-column
              :label="$t('COMMON.AMOUNT')"
              prop="pricing.total"
              min-width="180"
            >
              <template v-slot="{ row }">
                <span>
                  {{ $formatCurrency(row.pricing.total) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.RECIPIENT')"
              sortable="custom"
              min-width="220"
            >
              <template v-slot="{ row }">
                <object-link :object="row.recipient" />
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.RESELLER')"
              sortable="custom"
              min-width="220"
              v-if="
                !filterReseller &&
                $currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)
              "
            >
              <template v-slot="{ row }">
                <reseller :reseller="row.reseller" />
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('RESELLER_INVOICES.EXPIRATION_TIME')"
              prop="expiration_time"
              sortable="custom"
              min-width="180"
            />
            <el-table-column
              :label="$t('COMMON.CREATED_AT')"
              prop="created_at"
              sortable="custom"
              min-width="180"
            />
            <el-table-column
              :label="$t('COMMON.STATUS')"
              prop="status"
              min-width="150"
            >
              <template slot-scope="{ row }">
                <reseller-invoice-status-badge :resellerInvoice="row" />
              </template>
            </el-table-column>
            <el-table-column fixed="right" min-width="70">
              <div slot-scope="{ row }" class="table-actions">
                <el-dropdown trigger="click">
                  <span class="el-dropdown-link">
                    <i class="far fa-ellipsis-v"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown" class="actions-dropdown">
                    <el-dropdown-item
                      v-if="
                        $currentUserCan(
                          $permissions.PERM_VIEW_RESELLER_INVOICES
                        )
                      "
                    >
                      <a
                        type="text"
                        @click="viewResellerInvoice(row)"
                        class="table-action view"
                        data-toggle="tooltip"
                      >
                        <span class="icon">
                          <img src="/img/kw-eye.svg" alt="icon" />
                        </span>
                        <span class="text">{{
                          $t("COMMON.VIEW_DETAILS")
                        }}</span>
                      </a>
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-if="
                        $currentUserCan(
                          $permissions.PERM_EDIT_RESELLER_INVOICES
                        ) && row.status == INVOICE_STATUS_DRAFT
                      "
                    >
                      <a
                        type="text"
                        @click="editResellerInvoice(row)"
                        class="table-action edit"
                        data-toggle="tooltip"
                      >
                        <span class="icon">
                          <img src="/img/kw-edit.svg" alt="icon" />
                        </span>
                        <span class="text">
                          {{ $t("COMMON.EDIT") }}
                        </span>
                      </a>
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-if="
                        $currentUserCan(
                          $permissions.PERM_DELETE_RESELLER_INVOICES
                        ) && row.status == INVOICE_STATUS_DRAFT
                      "
                    >
                      <a
                        type="text"
                        @click="deleteResellerInvoice(row.id)"
                        class="table-action delete"
                        data-toggle="tooltip"
                      >
                        <span class="icon">
                          <img src="/img/kw-delete.svg" alt="icon" />
                        </span>
                        <span class="text">
                          {{ $t("COMMON.DELETE") }}
                        </span>
                      </a>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div
        v-if="resellerInvoices.length > 0"
        slot="footer"
        class="page-wrapper-table-footer"
      >
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}
          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-binvoice"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </div>
    </div>
    <div v-if="resellerInvoices.length === 0" class="no-data">
      <div class="no-data-inner">
        <img src="/img/kw-empty-box.svg" alt="icon" />
        <p>{{ $t("RESELLER_INVOICES.NO_DATA") }}</p>
        <base-button
          class="kw-button add"
          icon
          size="sm"
          @click="onAddResellerInvoice"
          v-if="$currentUserCan($permissions.PERM_CREATE_RESELLER_INVOICES)"
        >
          <span class="btn-inner--icon">
            <i class="far fa-plus"></i>
          </span>
          <span class="btn-inner--text">{{
            $t("RESELLER_INVOICES.ADD_RESELLER_INVOICE")
          }}</span>
        </base-button>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import flatPicker from "vue-flatpickr-component";
import { French } from "flatpickr/dist/l10n/fr.js";
import "flatpickr/dist/flatpickr.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  invoiceStatusesOption,
  INVOICE_STATUS_DRAFT,
} from "@/constants/invoices";
import { BasePagination } from "@/components";
import ResellerSelector from "@/components/ResellerSelector.vue";
import ResellerInvoiceStatusBadge from "./ResellerInvoiceStatusBadge.vue";

export default {
  name: "reseller-invoice-list-table",

  components: {
    BasePagination,
    flatPicker,
    ResellerSelector,
    ResellerInvoiceStatusBadge,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {
    filterReseller: {
      type: String,
      default: null,
      description: "Reseller id",
    },
    filterRecipient: {
      type: Object,
      default: null,
      description: "Object with 'recipient_id' and 'recipient_type' ",
    },
    filterStatus: {
      type: String,
      default: null,
      description: "Status",
    },
  },

  data() {
    return {
      flatPickrLocale: French,
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      resellerInvoices: [],
      loading: true,
      dateRange: null,
      selectedReseller: null,
      selectedStatus: null,
      selectedRecipient: null,
      statusesOptions: invoiceStatusesOption,
      INVOICE_STATUS_DRAFT: INVOICE_STATUS_DRAFT,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterReseller: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedReseller: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterRecipient: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedRecipient: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterStatus: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedStatus: {
      handler: "getListDebounced",
      immediate: true,
    },
    dateRange: {
      handler: "getListDebounced",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        const vDateRange = this.dateRange
          ? this.dateRange.split(this.flatPickrLocale.rangeSeparator)
          : [];
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { name: this.query } : {}),
            ...(vDateRange.length == 2
              ? {
                  created_at: [
                    vDateRange[0] + " 00:00:00",
                    vDateRange[1] + " 23:59:59",
                  ],
                }
              : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "reseller,recipient",
        };

        if (this.filterReseller) {
          params = {
            ...params,
            filter: { ...params.filter, reseller: this.filterReseller },
          };
        }
        if (this.selectedReseller) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              reseller: this.selectedReseller,
            },
          };
        }
        if (this.filterRecipient) {
          if (this.filterRecipient.type && this.filterRecipient.id) {
            params = {
              ...params,
              filter: {
                ...params.filter,
                recipient_type: this.filterRecipient.type,
                recipient_id: this.filterRecipient.id,
              },
            };
          }
        }
        if (this.selectedRecipient) {
          if (this.selectedRecipient.type && this.selectedRecipient.id) {
            params = {
              ...params,
              filter: {
                ...params.filter,
                recipient_type: this.selectedRecipient.type,
                recipient_id: this.selectedRecipient.id,
              },
            };
          }
        }
        if (this.filterStatus) {
          params = {
            ...params,
            filter: { ...params.filter, status: this.filterStatus },
          };
        }
        if (this.selectedStatus) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              status: this.selectedStatus,
            },
          };
        }

        await this.$store.dispatch("resellerInvoices/list", params);
        this.resellerInvoices = this.$store.getters["resellerInvoices/list"];
        this.total = this.$store.getters["resellerInvoices/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async deleteResellerInvoice(id) {
      const confirmation = await swal.fire({
        title: this.$t("RESELLER_INVOICES.DELETE_THIS_RESELLER_INVOICE"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("resellerInvoices/destroy", id);
          await this.getList();
          this.$notify({
            type: "success",
            message: this.$t("RESELLER_INVOICES.RESELLER_INVOICE_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    onAddResellerInvoice() {
      this.$emit("onAddResellerInvoice");
    },

    viewResellerInvoice(resellerInvoice) {
      this.$emit("onViewResellerInvoice", resellerInvoice);
    },

    editResellerInvoice(resellerInvoice) {
      this.$emit("onEditResellerInvoice", resellerInvoice);
    },

    sortChange({ prop, resellerInvoice }) {
      if (resellerInvoice === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
